import React from 'react';
import { Formik } from 'formik';
import { Form, Message } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { FormRow, Button, Flex, UniSelect } from 'components';

import { clientUpdateSubscriptionSelectedPrices } from './sdk';

export default function ManagePriceSeriesModal({
  client,
  clientItem,
  selectedPrices,
  fetchClient
}) {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await clientUpdateSubscriptionSelectedPrices(
      client.id,
      clientItem.id,
      {
        ...values
      }
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Access to prices updated.');
      fetchClient();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  const initialValues = {
    prices: selectedPrices
  };

  return (
    <div>
      <Message>
        Changing the prices the cilent has access to would also update their
        snapshot.
      </Message>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        initialValues={initialValues}>
        {({ handleSubmit }) => (
          <Form>
            <FormRow>
              <UniSelect
                isMulti
                transferList
                closeMenuOnSelect
                showCount
                name="prices"
                label="Price series"
                source="priceSeries"
              />
            </FormRow>
            <FormRow>
              <Flex spaceBetween style={{ width: '100%' }}>
                <Button type="submit" color="green" onClick={handleSubmit}>
                  Submit
                </Button>
              </Flex>
            </FormRow>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import _ from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { parseQueryString } from 'utils';

export const INITIAL_SORT_STATE = {
  field: null,
  direction: null
};

export const SORT_KEY = 'sort';

export const SORT_URL_KEY = 'order';

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const getInitialSort = (initialSort = null) => {
  if (initialSort) {
    return { [SORT_KEY]: initialSort };
  }

  return { [SORT_KEY]: INITIAL_SORT_STATE };
};

export const getSort = state => {
  return state[SORT_KEY];
};

export const updateSort = (state, field) => {
  // ASC -> DESC -> NONE
  const sort = getSort(state);
  const currentField = sort.field;

  // We don't have any sort field yet.

  if (!currentField) {
    return {
      field,
      direction: ASCENDING
    };
  }

  // We want to sort, based on another field

  if (currentField !== field) {
    return {
      field,
      direction: ASCENDING
    };
  }

  // Toggle existing field
  const currentDirection = sort.direction;

  if (currentDirection === ASCENDING) {
    return {
      field,
      direction: DESCENDING
    };
  }

  return {
    field: null,
    direction: null
  };
};

export const buildSort = sort => {
  const { field, direction } = sort;

  if (!field || !direction) {
    return {};
  }

  if (direction === ASCENDING) {
    return { order: field };
  }

  return { order: `-${field}` };
};

export const getSortFromUrl = url => {
  const urlParams = parseQueryString(url);

  const sortField = urlParams.order;

  const result = { ...INITIAL_SORT_STATE };

  if (!sortField) {
    return result;
  }

  let direction = null;
  let field = null;

  if (sortField.startsWith('-')) {
    direction = DESCENDING;
    field = sortField.split('-')[1];
  } else {
    direction = ASCENDING;
    field = sortField;
  }

  result.direction = direction;
  result.field = field;

  return result;
};

export const useSortable = ({
  defaultData,
  caseInsensitive = false,
  defaultField = null,
  defaultDirection = null
}) => {
  /*
    This is a generic hook that can be used if you want to have orderable data fully on the front-end,
    without caring about updating query params or executing API calls.
    The use case for this hook is when you have non-paginated data that you want to sort, without calling the back-end.
  */
  const [data, setData] = useState(defaultData);

  const [sortField, setSortField] = useState(defaultField);
  const [sortDirection, setSortDirection] = useState(defaultDirection); // "asc" | "desc" | null

  const sort = useMemo(() => ({ field: sortField, direction: sortDirection }), [
    sortField,
    sortDirection
  ]);

  const handleSortUpdate = useCallback(
    updatedSortField => {
      // We reuse the `updateSort` function from the existing utils,
      // because we want to keep the same behavior for the FE-only sorting
      const { field: newSortField, direction: newSortDirection } = updateSort(
        { sort },
        updatedSortField
      );

      setSortField(newSortField);
      setSortDirection(newSortDirection);
    },
    [sort]
  );

  useEffect(() => {
    // When the sorting is re-set - reset the data state back to the default.
    if (sortField === null && sortDirection === null) {
      setData(defaultData);
      return;
    }

    // Whenever the sort field/direction is updated - sort the data.
    setData(prev =>
      _.orderBy(
        prev,
        [
          data => {
            const value = _.get(data, sortField, '');
            return caseInsensitive ? value.toLowerCase() : value;
          }
        ],
        [sortDirection]
      )
    );
  }, [defaultData, caseInsensitive, sortField, sortDirection]);

  return useMemo(() => ({ data, sort, handleSortUpdate }), [
    data,
    sort,
    handleSortUpdate
  ]);
};

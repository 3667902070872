import React from 'react';

import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { Form, Divider } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  ErrorsAndUpdateButtonInRow,
  FormRow,
  InputField,
  AdminSelect,
  RegionSelect,
  CheckboxField,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues,
  UniSelect
} from 'components';

import { validationSchema, getInitialValues, preparePostData } from './utils';
import { priceSeriesUpdate } from './sdk';
import { HYDROGEN_SLUG } from 'domainConstants';
import { DisableableElement } from 'permissions';

function PriceSeriesUpdateForm({ priceSeries, fetchPriceSeries }) {
  const { productSlug } = useParams();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = preparePostData(values);

    const { errors, success } = await priceSeriesUpdate(priceSeries.id, data);

    setSubmitting(true);

    if (success) {
      notifySuccess('Price has been updated successfully.');
      fetchPriceSeries();
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  const initialValues = getInitialValues(priceSeries);

  const { createdAt, createdBy, updatedAt, updatedBy } = initialValues;

  const nonFormValues = {
    createdAt,
    createdBy,
    updatedAt,
    updatedBy
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}>
      {({ values, isSubmitting, handleSubmit, dirty, touched, errors }) => (
        <Form>
          <UnsavedFormPrompt when={dirty} />
          <FormRow>
            <Field
              width={2}
              name="activeInWebsite"
              label="Show on home page"
              component={CheckboxField}
            />

            <Field
              width={2}
              name="activeInNewsletters"
              label="Show in right column"
              component={CheckboxField}
            />
          </FormRow>
          <FormRow>
            <Field
              required
              width={6}
              name="title"
              label="Title:"
              component={InputField}
            />

            <Field
              required
              width={4}
              name="shortTitle"
              label="Short title:"
              component={InputField}
            />
          </FormRow>
          <FormRow>
            <Field width={6} name="slug" label="Slug:" component={InputField} />
            <Field
              width={4}
              name="displayAs"
              label="Display as:"
              component={InputField}
            />
          </FormRow>
          <DisableableElement disabled={productSlug === HYDROGEN_SLUG}>
            <FormRow>
              <UniSelect
                required
                width={5}
                name="groupId"
                label="Category:"
                source="priceSerieCategories"
                productSlug={productSlug}
              />

              <UniSelect
                required
                width={5}
                name="product"
                label="Product:"
                source="priceSerieProducts"
                productSlug={productSlug}
              />
            </FormRow>
            <FormRow>
              <UniSelect
                required
                width={5}
                name="region"
                label="Region:"
                source="priceSerieRegions"
              />

              <UniSelect
                width={5}
                name="region2"
                label="Region 2:"
                source="priceSerieRegions"
              />
            </FormRow>
            <FormRow>
              <UniSelect
                required
                name="incoTerms"
                label="INCO terms:"
                source="priceSerieIncoTerms"
              />

              <UniSelect
                required
                name="format"
                label="Format:"
                source="priceSerieFormats"
              />

              <UniSelect
                required
                name="unit"
                label="Unit:"
                source="priceSerieUnits"
              />

              <UniSelect
                required
                name="frequency"
                label="Frequency:"
                source="priceSerieFrequencies"
              />
            </FormRow>
            <FormRow>
              <UniSelect
                required
                width={2}
                name="currency"
                label="Currency:"
                source="priceSerieCurrencies"
              />

              <UniSelect
                required
                width={2}
                name="scale"
                label="Scale:"
                source="priceSerieScales"
              />
              <Field
                width={3}
                name="decimalPlaces"
                label="Decimal places:"
                component={InputField}
                type="number"
              />
            </FormRow>
            <FormRow>
              <UniSelect
                required
                width={4}
                name="priceDataSource"
                label="Price Data Source"
                source="priceSerieDataSources"
              />
              {values['priceDataSource'] &&
                values['priceDataSource'] !== 'Manual' && (
                  <Field
                    width={4}
                    name="externalSourceSymbol"
                    label="Price Data External Symbols:"
                    component={InputField}
                    required
                  />
                )}
            </FormRow>
          </DisableableElement>
          <FormRow>
            <AdminSelect
              width={4}
              name="principalOwner"
              label="Principal owner"
            />
          </FormRow>

          <AdminSelect
            width={16}
            name="access"
            isMulti={true}
            label="Access:"
            enableSelectAll
          />

          <RegionSelect
            isMulti={true}
            name="articleRegions"
            label="Region for articles:"
            enableSelectAll
          />
          <UniSelect
            isMulti={true}
            disabled={productSlug === HYDROGEN_SLUG}
            placeholder="Hydrogen"
            name="articleCategories"
            label="Prices for articles:"
            source="priceSerieCategories"
            productSlug={productSlug}
            enableSelectAll
          />

          <UniSelect
            isMulti={true}
            disabled={productSlug === HYDROGEN_SLUG}
            placeholder="Hydrogen"
            name="articleProducts"
            label="Products for articles:"
            source="priceSerieProducts"
            productSlug={productSlug}
            enableSelectAll
          />
          <AdminSelect
            isMulti={true}
            name="articleAuthors"
            label="Authors for articles:"
            enableSelectAll
          />
          <Divider />
          <FormRow widths="equal">
            <Field name="metaTitle" label="Meta Title" component={InputField} />

            <Field
              name="metaKeywords"
              label="Meta Keywords"
              component={InputField}
            />

            <Field
              name="metaDescription"
              label="Meta Description"
              component={InputField}
            />

            <Field
              name="metaStandout"
              label="Meta Standout"
              component={InputField}
            />
          </FormRow>
          <CreatedUpdatedFormValues values={nonFormValues} />
          <ErrorsAndUpdateButtonInRow
            subject="Price Serie"
            onClick={handleSubmit}
            disabled={isSubmitting}
            errors={errors}
            touched={touched}
          />
        </Form>
      )}
    </Formik>
  );
}

export default PriceSeriesUpdateForm;

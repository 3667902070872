import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklySteelDetail = async id => {
  const url = `${BASE_URL}/newsletters/weekly-steel/${id}/`;

  return await requestSdk(() => get(url));
};

export const weeklySteelUpdate = async (weeklySteelId, data) => {
  const url = `${BASE_URL}/newsletters/weekly-steel/update/${weeklySteelId}/`;

  return await requestSdk(() => post(url, data));
};

export const seasmEditionDetail = async id => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/${id}/`;

  return await requestSdk(() => get(url));
};

export const seasmEditionUpdate = async (weeklySteelId, data) => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/update/${weeklySteelId}/`;

  return await requestSdk(() => post(url, data));
};

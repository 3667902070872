import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import {
  Header,
  Page,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Flex,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { PERMISSIONS, PermissionsRequired, VisibleIf } from 'permissions';
import { WeeklySteelTable, withEditionDetails } from './components';

import { weeklySteelList, seasmEditionList } from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';

import { WeeklySteelCreate, SEASMEdtionCreate } from '../Create';
import { WeeklySteelDetail, SEASMEditionDetail } from '../Detail';

class WeeklyEditionList extends React.Component {
  createWeeklySteel = () => {
    const { createPage } = this.props.editionDetails;
    const location = pageUrl(createPage);

    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort,
      crumbs,
      editionDetails
    } = this.props;

    const { createPermission, title, detailPage } = editionDetails;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>{title}</Header>
              <VisibleIf user={user} permission={createPermission}>
                <Button size="large" onClick={this.createWeeklySteel}>
                  Create {title}
                </Button>
              </VisibleIf>
            </Flex>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  clearFilters={clear}
                  filterBy={filterBy}
                  count={count}
                  layout={LAYOUT_FILTERS}
                />
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <WeeklySteelTable
                    items={data}
                    orderBy={orderBy}
                    sort={sort}
                    detailPage={detailPage}
                    pagination={
                      <Pagination
                        limit={limit}
                        count={count}
                        page={page}
                        changePage={changePage}
                        changeRowsPerPage={changeRowsPerPage}
                      />
                    }
                  />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export const WeeklySteelList = ListViewEssentials({
  sdk: weeklySteelList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_WEEKLY_STEEL])(
      withRouter(
        withEditionDetails({
          editionDetails: {
            title: 'Weekly Steel',
            createPermission: PERMISSIONS.CREATE_WEEKLY_STEEL,
            createPage: WeeklySteelCreate,
            detailPage: WeeklySteelDetail
          }
        })(WeeklyEditionList)
      )
    )
  )
);

export const SEASMEditionList = ListViewEssentials({
  sdk: seasmEditionList
})(
  LoginRequired(
    // TODO: change permission
    PermissionsRequired([PERMISSIONS.VIEW_SEASM_REPORT])(
      withRouter(
        withEditionDetails({
          editionDetails: {
            title: 'SEASM Reports',
            // TODO: change permissions
            createPermission: PERMISSIONS.CREATE_SEASM_REPORT,
            createPage: SEASMEdtionCreate,
            detailPage: SEASMEditionDetail
          }
        })(WeeklyEditionList)
      )
    )
  )
);

import _ from 'lodash';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { PriceSerieCreate } from 'pages';

import {
  Page,
  Button,
  ListViewEssentials,
  Pagination,
  Flex,
  Header,
  Filters as SharedFilters
} from 'components';

import { PERMISSIONS, VisibleIf } from 'permissions';

import { withLoggedUser, withPermissions } from 'hooks';

import { PriceSeriesTable, CategoryOrderModal } from './components';

import { priceSeriesList } from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';
import { HYDROGEN_SLUG } from 'domainConstants';

function PriceSerieList({
  user,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  crumbs,
  history
}) {
  const [isOpenCategoryOrderModal, setCategoryOrderModal] = useState(false);
  const { productSlug } = useParams();

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header>Price Series</Header>
            <VisibleIf user={user} permissions={[PERMISSIONS.CREATE_PRICES]}>
              <div>
                <Button
                  color="yellow"
                  size="large"
                  onClick={() =>
                    setCategoryOrderModal(prevValue => !prevValue)
                  }>
                  Order Prices for Category
                </Button>
                {productSlug !== HYDROGEN_SLUG && (
                  <Button
                    size="large"
                    onClick={() =>
                      history.push(pageUrl(PriceSerieCreate, { productSlug }))
                    }>
                    Add Price series
                  </Button>
                )}
              </div>
            </VisibleIf>
          </Flex>
          {data && (
            <>
              <SharedFilters
                filters={filters}
                count={count}
                filterBy={filterBy}
                clearFilters={clear}
                layout={LAYOUT_FILTERS(productSlug)}
              />
              <PriceSeriesTable
                data={data}
                sort={sort}
                orderBy={orderBy}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            </>
          )}
        </Page.Content>
        {isOpenCategoryOrderModal && (
          <CategoryOrderModal
            onClose={() => setCategoryOrderModal(prevValue => !prevValue)}
            productSlug={productSlug}
          />
        )}
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(priceSeriesList, productSlug);
  },
  passPropsToSdk: true
})(withLoggedUser(withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSerieList)));

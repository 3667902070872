import _ from 'lodash';

import React from 'react';

import {
  Modal as SemanticModal,
  Icon,
  Header,
  Button
} from 'semantic-ui-react';

import { Flex } from 'components';

class Modal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  render() {
    const { children, header, additonalActions, ...rest } = this.props;

    return (
      <SemanticModal open={true} size="large" centered={false} {...rest}>
        <SemanticModal.Header>
          <Flex spaceBetween>
            <Header as="h2" style={{ margin: 0 }}>
              {header}
            </Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </SemanticModal.Header>
        <SemanticModal.Content>{children}</SemanticModal.Content>
        <SemanticModal.Actions>
          <Button color="red" onClick={this.close}>
            Close
          </Button>
          {!_.isEmpty(additonalActions) ? additonalActions : null}
        </SemanticModal.Actions>
      </SemanticModal>
    );
  }
}

export default Modal;

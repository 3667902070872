import React from 'react';

import _ from 'lodash';

import { Button, Icon } from 'semantic-ui-react';

import { Flex } from 'components';

import {
  getNonSelectedOptions,
  getSelectedOptions,
  isOptionSelected
} from './utils';

import OrderSelectedOptionsModal from './OrderSelectedOptionsModal';

import styles from './styles.module.css';

class TransferList extends React.Component {
  state = {
    valuesToAdd: [],
    valuesToRemove: [],
    selectedOptions: [],
    nonSelectedOptions: [],
    currentFieldValue: null,
    options: [],
    searchValue: '',
    isOpenOrderSelectedOptionsModal: false
  };

  toggleOrderSelectedOptionsModal = () => {
    this.setState(prevState => ({
      isOpenOrderSelectedOptionsModal: !prevState.isOpenOrderSelectedOptionsModal
    }));
  };

  setFieldValue = (value, callback) => {
    const { options } = this.state;

    const selectedOptions = getSelectedOptions(options, value);

    const nonSelectedOptions = getNonSelectedOptions(options, value);

    this.setState(
      {
        currentFieldValue: value,
        selectedOptions,
        nonSelectedOptions
      },
      callback
    );
    this.props.onChange(value);
  };

  toggleOptionsToAdd = ({ options, callback }) => {
    const valuesToAdd = options.map(option =>
      parseInt(option.value) ? parseInt(option.value) : option.value
    );

    this.setState({ valuesToAdd }, callback);
  };

  toggleOptionsToRemove = ({ options, callback }) => {
    const valuesToRemove = options.map(option =>
      parseInt(option.value) ? parseInt(option.value) : option.value
    );

    this.setState(
      {
        valuesToRemove
      },
      callback
    );
  };

  addFormValues = () => {
    const { currentFieldValue, valuesToAdd } = this.state;

    this.setFieldValue(_.concat(currentFieldValue, valuesToAdd), () =>
      this.setState({ valuesToAdd: [] })
    );
  };

  removeFormValues = () => {
    const { currentFieldValue } = this.state;

    const newValues = _.xor(currentFieldValue, this.state.valuesToRemove);

    this.setFieldValue(newValues, () => this.setState({ valuesToRemove: [] }));
  };

  search = value => {
    this.setState({
      searchValue: value
    });
  };

  orderSelectedOptions = options => {
    const value = _.map(options, option => option.value);

    this.props.onChange(value);
    this.toggleOrderSelectedOptionsModal();
  };

  componentDidMount() {
    const { options, value } = this.props;

    this.setState({
      options: options,
      currentFieldValue: value,
      nonSelectedOptions: getNonSelectedOptions(options, value),
      selectedOptions: getSelectedOptions(options, value)
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.options, this.props.options)) {
      const { options, value } = this.props;

      this.setState({
        options: options,
        currentFieldValue: value,
        nonSelectedOptions: getNonSelectedOptions(options, value),
        selectedOptions: getSelectedOptions(options, value)
      });

      return;
    }

    if (!_.isEqual(this.props.value, prevProps.value)) {
      const { value } = this.props;
      const { options } = this.state;

      if (_.isEmpty(options)) {
        return;
      }

      this.setState({
        currentFieldValue: value,
        nonSelectedOptions: getNonSelectedOptions(options, value),
        selectedOptions: getSelectedOptions(options, value)
      });
    }
  }

  render() {
    const {
      label,
      showCount = false,
      enableOrdering = false,
      enableSelectedSearch = false
    } = this.props;
    const { valuesToAdd, valuesToRemove, searchValue } = this.state;

    let { selectedOptions, nonSelectedOptions } = this.state;

    if (!_.isEmpty(searchValue)) {
      nonSelectedOptions = nonSelectedOptions.filter(nonSelectionOption =>
        _.includes(
          _.lowerCase(nonSelectionOption.label),
          _.lowerCase(searchValue)
        )
      );

      if (enableSelectedSearch) {
        selectedOptions = selectedOptions.filter(option =>
          _.includes(_.lowerCase(option.label), _.lowerCase(searchValue))
        );
      }
    }

    return (
      <>
        <div>
          <div className={styles.container}>
            <div>
              <label>
                Available {label}
                {showCount && ` (${nonSelectedOptions.length})`}
              </label>
              <input
                className={styles.input}
                type="text"
                onChange={e => this.search(e.target.value)}
                placeholder="Search..."
              />
              <select
                multiple
                onChange={e =>
                  this.toggleOptionsToAdd({
                    options: Array.from(e.target.selectedOptions)
                  })
                }
                onDoubleClick={e => {
                  if (!_.isEmpty(e.target.value)) {
                    this.toggleOptionsToAdd({
                      options: [e.target],
                      callback: this.addFormValues
                    });
                  }
                }}>
                {nonSelectedOptions.map(option => (
                  <option
                    key={`option-${option.value}`}
                    value={option.value}
                    selected={isOptionSelected(valuesToAdd, option.value)}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.btnsColumn}>
              <Button
                circular
                onClick={this.addFormValues}
                icon
                color="green"
                disabled={_.isEmpty(this.state.valuesToAdd)}>
                <Icon
                  size="large"
                  name="arrow alternate circle right outline"
                />
              </Button>
              <Button
                circular
                onClick={this.removeFormValues}
                icon
                color="red"
                disabled={_.isEmpty(this.state.valuesToRemove)}>
                <Icon size="large" name="arrow alternate circle left outline" />
              </Button>
            </div>

            <div className={styles.selectedOptions}>
              <Flex spaceBetween className={styles.header}>
                <label>
                  Chosen {label}
                  {showCount && ` (${selectedOptions.length})`}
                </label>
                {enableOrdering && (
                  <div>
                    <Button
                      color="blue"
                      disabled={_.isEmpty(selectedOptions)}
                      onClick={this.toggleOrderSelectedOptionsModal}>
                      Order {label}
                    </Button>
                  </div>
                )}
              </Flex>
              <select
                multiple
                onChange={e =>
                  this.toggleOptionsToRemove({
                    options: Array.from(e.target.selectedOptions)
                  })
                }
                onDoubleClick={e => {
                  if (!_.isEmpty(e.target.value)) {
                    this.toggleOptionsToRemove({
                      options: [e.target],
                      callback: this.removeFormValues
                    });
                  }
                }}>
                {selectedOptions.filter(_.negate(_.isUndefined)).map(option => (
                  <option
                    key={`option-${option.value}`}
                    value={option.value}
                    selected={isOptionSelected(valuesToRemove, option.value)}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {this.state.isOpenOrderSelectedOptionsModal && (
          <OrderSelectedOptionsModal
            onClose={this.toggleOrderSelectedOptionsModal}
            label={label}
            options={selectedOptions}
            orderSelectedOptions={this.orderSelectedOptions}
          />
        )}
      </>
    );
  }
}
export default TransferList;

import _ from 'lodash';
import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  CursorPagination,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';
import { getProductName } from 'utils';
import useArchiveCount from 'hooks/useArchiveCount';

import { emailLogCount, emailLogList } from './sdk';

import { LogsTable } from '../components';

import { FILTERS } from './utils';

/*
 Email log component for the new Daily Email Logs - NewsletterIssueEmailLog model
 Same as other EmailLogList, but no other kind of email logs for now.
 Works just for the daily newsletter logs distinguished by product (Steel, Power Material)
*/

const EmailLogListV2 = ({
  user,
  data,
  nextCursor,
  previousCursor,
  pageSize,
  changePage,
  changeRowsPerPage,
  clear,
  filterBy,
  filters,
  match,
  sort,
  orderBy
}) => {
  const { productSlug } = match.params;

  const {
    fetchLogCount,
    logsCount,
    clearFilters,
    filterAndClearCount,
    hasAppliedFilters,
    loading
  } = useArchiveCount({
    sdk: _.partial(emailLogCount, productSlug, filters),
    clear,
    filterBy,
    filters
  });

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header>{getProductName(productSlug)} Email Archive</Header>
          </Flex>
          {data && (
            <>
              <SharedFilters
                filters={filters}
                filterBy={filterAndClearCount}
                clearFilters={clearFilters}
                count={logsCount}
                layout={FILTERS}
              />

              {_.isEmpty(data) && <NoDataMessage />}
              {!_.isEmpty(data) && (
                <LogsTable
                  logs={data}
                  hasAppliedFilters={hasAppliedFilters}
                  fetchLogCount={fetchLogCount}
                  logsCount={logsCount}
                  loading={loading}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <CursorPagination
                      nextCursor={nextCursor}
                      previousCursor={previousCursor}
                      pageSize={pageSize}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              )}
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export const DailyNewsletterEmailLogListV2 = ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    const { queryParams } = props;
    return _.partial(emailLogList, productSlug, queryParams);
  },
  passPropsToSdk: true,
  cursorPagination: true
})(LoginRequired(withRouter(EmailLogListV2)));

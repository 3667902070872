import {
  LATEST_ISSUE_DAILY_NEWSLETTERS,
  LATEST_ISSUE_WEEKLY_NEWSLETTERS,
  LATEST_ISSUE_WEEKLY_SEASM_NEWSLETTERS,
  CHINA_STEEL_INTELLIGENCE,
  LATEST_ISSUE_NEWSLETTER_ISSUES
} from 'domainConstants';

import {
  DailyNewsletters,
  WeeklyNewsletters,
  ChinaReports,
  NewsletterIssue
} from './components';

export const getSendLatestIssuePropsFromProduct = product => {
  let Component = null;
  let weeklyUrlFragment = null;
  let productSlug = null;
  let newsletterTypeSlug = null;

  if (LATEST_ISSUE_DAILY_NEWSLETTERS.includes(product.description)) {
    Component = DailyNewsletters;
  } else if (LATEST_ISSUE_WEEKLY_NEWSLETTERS.includes(product.description)) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'weekly';
  } else if (
    LATEST_ISSUE_WEEKLY_SEASM_NEWSLETTERS.includes(product.description)
  ) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'weekly-seasm';
  } else if (product.description === CHINA_STEEL_INTELLIGENCE) {
    Component = ChinaReports;
  }
  if (
    Object.keys(LATEST_ISSUE_NEWSLETTER_ISSUES).includes(product.description)
  ) {
    Component = NewsletterIssue;
    productSlug = LATEST_ISSUE_NEWSLETTER_ISSUES[product.description];
    newsletterTypeSlug = 'daily';
  }

  return {
    Component,
    productSlug,
    newsletterTypeSlug,
    weeklyUrlFragment
  };
};

export const EMAIL_TEMPLATE_TRIGGER = {
  MANUAL: 'Manual',
  CUSTOM: 'Custom',
  WELCOME_EMAIL: 'Welcome email',
  NEW_REGISTRATION: 'New registration',
  PASSWORD_RECOVERY: 'Password recovery',
  NEW_ORDER: 'New order',
  ORDER_STATUS_UPDATE: 'Order status update',
  SEND_INVOICE: 'Send Invoice',
  SEND_LATEST_ISSUE_FULL_EDITION: 'Send latest issue - Full edition',
  SEND_LATEST_ISSUE_PAYMENT_CHASING_NEW:
    'Send latest issue - Payment chasing new',
  SEND_LATEST_ISSUE_PAYMENT_CHASING_OLD:
    'Send latest issue - Payment chasing old',
  SEND_WEEKLY_STEEL_FULL_EDITION: 'Send Weekly Steel - Full edition',
  SEND_WEEKLY_STEEL_PAYMENT_CHASING_EDITION:
    'Send Weekly Steel - Payment chasing edition',
  SEND_WEEKLY_STEEL_TRIALS_EDITION: 'Send Weekly Steel - Trials edition',
  SEND_WEEKLY_SEASM_FULL_EDITION: 'Send Weekly Seasm - Full edition',
  SEND_WEEKLY_SEASM_PAYMENT_CHASING_EDITION:
    'Send Weekly Seasm - Payment chasing edition',
  SEND_WEEKLY_SEASM_TRIALS_EDITION: 'Send Weekly Seasm - Trials edition',
  SEND_WEEKLY_KPM_FULL_EDITION: 'Send Weekly KPM - Full edition',
  SEND_WEEKLY_KPM_PAYMENT_CHASING_EDITION:
    'Send Weekly KPM - Payment chasing edition',
  SEND_WEEKLY_KPM_TRIALS_EDITION: 'Send Weekly KPM - Trials edition',
  CSI_FULL_EDITION: 'CSI - Full edition',
  CSI_PAYMENT_CHASING_EDITION: 'CSI - Payment chasing edition',
  CSI_TRIALS_EDITION: 'CSI - Trials edition',
  NEW_MOBILE_APP_REGISTRATION: 'New Mobile app registration',
  PRICE_UPDATE_NOTIFICATION_EMAIL: 'Price update notification email',
  PRICE_SPREAD_UPDATE_NOTIFICATION_EMAIL:
    'Price Spread update notification email'
};

import React from 'react';

import { Button } from 'semantic-ui-react';
import { openInNewTab } from 'utils';

const ExternalLinkButton = props => {
  const { url, color, children, style, disabled = false } = props;

  return (
    <Button
      color={color || 'teal'}
      onClick={() => openInNewTab(url)}
      style={style}
      disabled={disabled}>
      {children}
    </Button>
  );
};

export default ExternalLinkButton;

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';

import {
  Segment,
  Table,
  Dimmer,
  Loader,
  Header,
  Button
} from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { Flex } from 'components';

import {
  MAIL_TARGET_TYPES_URLS,
  prepareData,
  TARGET_CUSTOM_COLORS
} from './utils';

class StatTable extends React.Component {
  state = {
    data: {},
    isLoading: true
  };

  componentDidMount() {
    this.getStats();

    this.pollInterval = setInterval(this.getStats, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
    this.pollInterval = null;
  }

  getStats = async () => {
    if (!document.hasFocus()) {
      return;
    }

    const { success, data } = await this.props.getStats();

    if (success) {
      this.setState({ data: prepareData(data), isLoading: false });
    }
  };

  getMailTargetUrl = targetId => {
    const { url, arg, additionalArgs = {} } = MAIL_TARGET_TYPES_URLS[
      this.props.type
    ];

    return pageUrl(url, { [arg]: targetId, ...additionalArgs });
  };

  // interruptMailTarget = async targetId => {
  //   const { success, errors } = await mailTargetInterrupt(
  //     MAIL_TARGET_TYPES[this.props.type],
  //     targetId
  //   );

  //   if (success) {
  //     notifySuccess('Sending successfully interrupted.');
  //   } else {
  //     notifyErrors(errors);
  //   }

  //   this.getStats();
  // };

  render() {
    const {
      data: { targetType, targets },
      isLoading
    } = this.state;

    const { receiversUrl } = this.props;

    return (
      <div>
        <Segment key={targetType}>
          {isLoading && (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          )}
          {!isLoading && (
            <>
              <Flex spaceBetween>
                <Header as="h2">{targetType}</Header>
                {receiversUrl && (
                  <Button
                    onClick={() => this.props.history.push(receiversUrl())}>
                    {targetType === 'Weekly Steel' ||
                    targetType === 'Weekly Seasm' ||
                    targetType === 'Weekly Power Materials'
                      ? 'View Opened'
                      : 'View Receivers'}
                  </Button>
                )}
              </Flex>
              <Table celled compact selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Date to go out</Table.HeaderCell>
                    <Table.HeaderCell>Sent emails</Table.HeaderCell>
                    <Table.HeaderCell>Failed</Table.HeaderCell>
                    <Table.HeaderCell>Interrupted</Table.HeaderCell>
                    <Table.HeaderCell>Total Email</Table.HeaderCell>
                    <Table.HeaderCell>% Done</Table.HeaderCell>
                    {/* {hasSendingObject && <Table.HeaderCell />} */}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {_.isEmpty(targets) && (
                    <Table.Row>
                      <Table.Cell colSpan="5" textAlign="center">
                        No ready for sending objects found.
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {!_.isEmpty(targets) &&
                    targets.map((target, index) => {
                      return (
                        <Table.Row
                          key={index}
                          textAlign="center"
                          style={{
                            backgroundColor:
                              TARGET_CUSTOM_COLORS[target.sendingStatus]
                          }}>
                          <Table.Cell key={`target-id-${target.targetId}`}>
                            {targetType !== 'Instant News' ? (
                              <Link to={this.getMailTargetUrl(target.targetId)}>
                                {target.targetId}
                              </Link>
                            ) : (
                              target.targetId
                            )}
                          </Table.Cell>

                          <Table.Cell>
                            <Header as="div" size="tiny">
                              {target.sendingStatus}
                            </Header>
                          </Table.Cell>

                          <Table.Cell>{target.sendWhen}</Table.Cell>
                          <Table.Cell>{target.sendEmailCount}</Table.Cell>
                          <Table.Cell>{target.failedEmailCount}</Table.Cell>
                          <Table.Cell>
                            {target.interruptedEmailCount}
                          </Table.Cell>

                          <Table.Cell>{target.totalMailCount}</Table.Cell>
                          <Table.Cell>{target.percentageDone}</Table.Cell>
                          {/* {hasSendingObject && (
                            <Table.Cell key={`${index}Interrupt`}>
                              {target.sendingStatus === 'Sending' && (
                                <Link
                                  onClick={() =>
                                    this.interruptMailTarget(target.targetId)
                                  }>
                                  Interrupt Sending
                                </Link>
                              )}
                            </Table.Cell>
                          )} */}
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </>
          )}
        </Segment>
      </div>
    );
  }
}

export default withRouter(StatTable);

import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import withEditionDetails from '../withEditionDetails';

import { WeeklySteelCreateForm } from './components';
import {
  weeklySteelCreate,
  weeklySteelCreateTemplates,
  seasmEditionCreate,
  seasmEditionTemplates
} from './sdk';

class WeeklyEditionCreate extends React.Component {
  render() {
    const { user, crumbs, editionDetails } = this.props;

    const {
      title,
      editionCreateSdk,
      editionTemplatesSdk,
      successPage
    } = editionDetails;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create {title}</Header>
              <WeeklySteelCreateForm
                editionTemplatesSdk={editionTemplatesSdk}
                editionCreateSdk={editionCreateSdk}
                successPage={successPage}
                title={title}
              />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

const WeeklySteelCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_WEEKLY_STEEL])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: weeklySteelCreate,
        editionTemplatesSdk: weeklySteelCreateTemplates,
        title: 'Weekly Steel'
      }
    })(WeeklyEditionCreate)
  )
);

const SEASMEdtionCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_SEASM_REPORT])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: seasmEditionCreate,
        editionTemplatesSdk: seasmEditionTemplates,
        title: 'SEASM report'
      }
    })(WeeklyEditionCreate)
  )
);

export { WeeklySteelCreate, SEASMEdtionCreate };

import _ from 'lodash';

export const PERMISSIONS = {
  VIEW_INVOICES: 'View Invoices',
  CREATE_INVOICES: 'Create Invoices',
  CHANGE_INVOICES: 'Change Invoices',
  DELETE_INVOICES: 'Delete Invoices',
  SEARCH_INVOICES: 'Search Invoices',
  SEND_INVOICES: 'Send Invoices',

  VIEW_CLIENTS: 'View Clients',
  CREATE_CLIENTS: 'Create Clients',
  CHANGE_CLIENTS: 'Change Clients',
  DELETE_CLIENTS: 'Delete Clients',
  SEARCH_CLIENTS: 'Search Clients',
  SEND_EMAILS_TO_CLIENTS: 'Send Emails to Clients',

  VIEW_PRODUCTS: 'View Products',
  CREATE_PRODUCTS: 'Create Products',
  CHANGE_PRODUCTS: 'Change Products',
  DELETE_PRODUCTS: 'Delete Products',

  VIEW_ARTICLES: 'View Articles',
  CREATE_ARTICLES: 'Create Articles',
  CHANGE_ARTICLES: 'Change Articles',
  DELETE_ARTICLES: 'Delete Articles',
  PUBLISH_ARTICLES: 'Publish Articles',
  SEARCH_ARTICLES: 'Search Articles',
  CHANGE_RFP_ARTICLES: 'Change RFP Articles',

  VIEW_PRICES: 'View Prices',
  CREATE_PRICES: 'Create Prices',
  CHANGE_PRICES: 'Change Prices',
  DELETE_PRICES: 'Delete Prices',
  PUBLISH_PRICES: 'Publish Prices',

  VIEW_STAFF: 'View Staff',
  CREATE_STAFF: 'Create Staff',
  CHANGE_STAFF: 'Change Staff',
  DELETE_STAFF: 'Delete Staff',

  VIEW_GROUPS: 'View Groups',
  CREATE_GROUPS: 'Create Groups',
  CHANGE_GROUPS: 'Change Groups',
  DELETE_GROUPS: 'Delete Groups',

  VIEW_MAILSHOTS: 'View Mailshots',
  CREATE_MAILSHOTS: 'Create Mailshots',
  CHANGE_MAILSHOTS: 'Change Mailshots',
  DELETE_MAILSHOTS: 'Delete Mailshots',

  VIEW_NEWSLETTERS: 'View Newsletters',
  CREATE_NEWSLETTERS: 'Create Newsletters',
  CHANGE_NEWSLETTERS: 'Change Newsletters',
  DELETE_NEWSLETTERS: 'Delete Newsletters',
  SEND_CUSTOM_NEWSLETTER_EMAILS: 'Send Custom Newsletter Emails',

  VIEW_WEEKLY_STEEL: 'View Weekly Steel',
  CREATE_WEEKLY_STEEL: 'Create Weekly Steel',
  CHANGE_WEEKLY_STEEL: 'Change Weekly Steel',
  DELETE_WEEKLY_STEEL: 'Delete Weekly Steel',

  VIEW_WEEKLY_POWER_MATERIALS: 'View Weekly Power Materials',
  CREATE_WEEKLY_POWER_MATERIALS: 'Create Weekly Power Materials',
  CHANGE_WEEKLY_POWER_MATERIALS: 'Change Weekly Power Materials',
  DELETE_WEEKLY_POWER_MATERIALS: 'Delete Weekly Power Materials',

  VIEW_ORDERS: 'View Orders',
  CHANGE_ORDERS: 'Change Orders',

  VIEW_EVENTS_EXPORTS: 'View Events Exports',

  VIEW_CORPORATE_RSS_LOGS: 'View Corporate RSS Logs',

  VIEW_SEASM_REPORT: 'View SEASM report',
  CREATE_SEASM_REPORT: 'Create SEASM report',
  CHANGE_SEASM_REPORT: 'Change SEASM report',
  DELETE_SEASM_REPORT: 'Delete SEASM report',

  DOWNLOAD_CM_CHILDREN_EXPORT: 'Download CM Children Export',

  IMPORT_CLIENTS_FROM_SPREADSHEET: 'Import Clients from Spreadsheet',

  BULK_CHANGE_CLIENTS: 'Bulk Change Clients'
};

export const hasPermission = (user, permission) => {
  if (_.isNil(user)) {
    return false;
  }

  const userPermissions = _.get(user, 'permissions', {});

  return _.get(userPermissions, permission) === true;
};

export const hasPermissions = (user, permissions) => {
  if (_.isEmpty(permissions)) {
    return true;
  }

  return (
    hasPermission(user, _.head(permissions)) &&
    hasPermissions(user, _.tail(permissions))
  );
};

export const preparePermissions = data => {
  return _.map(PERMISSIONS, (v, k) => {
    const value = data.permissions.includes(v);
    return { action: v, value: value };
  });
};

export const prepareInitialPermissions = permissions => {
  return _.map(
    _.filter(permissions, permission => {
      return permission.value;
    }),
    permission => {
      return permission.action;
    }
  );
};

export const hasHacksoftPermission = user =>
  user && _.endsWith(user.email, '@hacksoft.io');

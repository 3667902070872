import React from 'react';
import _ from 'lodash';

import { Page, Header, LoginRequired } from 'components';

import { pageUrl } from 'config/routes';

import {
  NewsletterReceiversList,
  NewsletterReceiversListV2,
  WeeklySeasmOpenCount,
  WeeklySteelOpenCount,
  WeeklyPowerMaterialsOpenCount
} from 'pages';

import { STEEL_SLUG, POWER_MATERIAL_SLUG } from 'domainConstants';

import { StatTable } from './components';

import { emailStatsBuilder, getNewsletterIssueStats } from './sdk';

import styles from './styles.module.css';

const EMAIL_TABLES = [
  {
    type: 'daily-newsletter',
    receiversUrl: () =>
      pageUrl(NewsletterReceiversList, { newsletter: 'daily' }),
    getStats: emailStatsBuilder('daily-newsletter')
  },
  {
    type: 'power-material-newsletter',
    receiversUrl: () =>
      pageUrl(NewsletterReceiversList, { newsletter: 'power-material' }),
    getStats: emailStatsBuilder('power-material-newsletter')
  },
  {
    type: 'weekly-steel',
    receiversUrl: () => pageUrl(WeeklySteelOpenCount),
    getStats: emailStatsBuilder('weekly-steel')
  },
  {
    type: 'weekly-seasm',
    receiversUrl: () => pageUrl(WeeklySeasmOpenCount),
    getStats: emailStatsBuilder('weekly-seasm')
  },
  {
    type: 'weekly-power-materials',
    receiversUrl: () => pageUrl(WeeklyPowerMaterialsOpenCount),
    getStats: emailStatsBuilder('weekly-power-materials')
  },
  { type: 'mailshot', getStats: emailStatsBuilder('mailshot') },
  { type: 'instant-news', getStats: emailStatsBuilder('instant-news') }
];

const STEEL_NEWSLETTER_EMAIL_TABLE = {
  type: `daily-${STEEL_SLUG}`,
  receiversUrl: () =>
    pageUrl(NewsletterReceiversListV2, {
      newsletter: STEEL_SLUG
    }),
  getStats: getNewsletterIssueStats(STEEL_SLUG)
};
const POWER_MATERIALS_NEWSLETTER_EMAIL_TABLE = {
  type: `daily-${POWER_MATERIAL_SLUG}`,
  receiversUrl: () =>
    pageUrl(NewsletterReceiversListV2, {
      newsletter: POWER_MATERIAL_SLUG
    }),
  getStats: getNewsletterIssueStats(POWER_MATERIAL_SLUG)
};

const GOOGLE_CALENDAR_URL =
  'https://calendar.google.com/calendar/embed?title=Marketing%20Email%20Schedule&height=600&wkst=2&bgcolor=%2399ff99&src=c_jhvmjiano8784vo3bnd9t1pp2o%40group.calendar.google.com&color=%2323164E&ctz=Europe%2FLondon';

const getEmailTables = () => {
  const tables = _.cloneDeep(EMAIL_TABLES);

  tables[0] = STEEL_NEWSLETTER_EMAIL_TABLE;
  tables[1] = POWER_MATERIALS_NEWSLETTER_EMAIL_TABLE;

  return tables;
};

class EmailDashboard extends React.Component {
  getCalendar = () => {
    return {
      __html: `<iframe
               src="${GOOGLE_CALENDAR_URL}"
               style="border: solid 1px #777"
               width="100%"
               height="600"
               frameborder="0"
               scrolling="no"></iframe>`
    };
  };

  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs} maxWidth>
            <Header>Email Dashboard</Header>
            <div
              className={styles.calendarWrapper}
              dangerouslySetInnerHTML={this.getCalendar()}
            />
            <div className={styles.elementsContainer}>
              {getEmailTables().map(({ type, receiversUrl, getStats }) => (
                <StatTable
                  key={type}
                  type={type}
                  receiversUrl={receiversUrl}
                  getStats={getStats}
                />
              ))}
              <div></div>
            </div>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(EmailDashboard);
